import React, { useState } from 'react';


// Sample exchange rates (replace with actual data or API)
const exchangeRates: {
  [key: string]: number;
  BTC: number;
  ETH: number;
  INR: number;
  USD: number;
} = {
  BTC: 0.000023,
  ETH: 0.00045,
  INR: 85,
  USD: 1,
};

const ExchangeMarketplace: React.FC = () => {
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState('EUR');
  const [outputAmount, setOutputAmount] = useState(0);
  const [exchangeClicked, setExchangeClicked] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [chatOption, setChatOption] = useState('');
  const [chatMessage, setChatMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const handleExchange = () => {
    const rate = exchangeRates[toCurrency] / exchangeRates[fromCurrency];
    const result = 1 * rate; // Just setting inputAmount as 1 for demonstration
    setOutputAmount(result);
    setExchangeClicked(true);
  };

  const handleChatOption = (option: string) => {
    setChatOption(option);
    setShowChat(true);
  };

  const closeChat = () => {
    setShowChat(false);
    setChatOption('');
    setChatMessage('');
    setMessageSent(false);
  };

  const sendMessage = () => {
    // Simulating sending message functionality
    setMessageSent(true);
  };
  const renderCloseButton = () => (
    <button onClick={closeChat} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
      <span className="text-lg">&times;</span>
    </button>
  );

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white font-poppins">
        <div className="flex justify-between max-w-4xl w-full">
          <div className="bg-gray-800 rounded-lg shadow-lg p-8 w-1/2 mr-4">
            <h1 className="text-3xl font-bold mb-6 text-center">Currency Exchange</h1>
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex flex-col w-full">
                <label htmlFor="fromCurrency" className="text-lg mb-2">
                  From:
                </label>
                <select
                  id="fromCurrency"
                  value={fromCurrency}
                  onChange={(e) => setFromCurrency(e.target.value)}
                  className="p-2 border border-gray-700 rounded w-full bg-gray-700 text-white"
                >
                  {Object.keys(exchangeRates).map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="toCurrency" className="text-lg mb-2">
                  To:
                </label>
                <select
                  id="toCurrency"
                  value={toCurrency}
                  onChange={(e) => setToCurrency(e.target.value)}
                  className="p-2 border border-gray-700 rounded w-full bg-gray-700 text-white"
                >
                  {Object.keys(exchangeRates).map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-between mb-4">
              <button onClick={handleExchange} className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600">
                Exchange
              </button>
            </div>
            <div
              className={`text-center transition-opacity duration-500 ${exchangeClicked ? 'opacity-100' : 'opacity-0'}`}
            >
              <p className="text-lg">
                1 {fromCurrency} = {outputAmount.toFixed(8)} {toCurrency}
              </p>
            </div>
          </div>
          <div className="bg-gray-800 rounded-lg shadow-lg p-8 w-1/2 ml-4">
            <h1 className="text-3xl font-bold mb-6 text-center">Market Stats</h1>
            {/* Showcase Liquidity and Transaction Volume */}
            <div className="mb-4">
              <p className="text-lg font-semibold mb-2">Liquidity</p>
              <p className="text-lg">Total Liquidity: $5,000,000</p>
              <p className="text-lg">Available Liquidity: $3,000,000</p>
            </div>
            <div>
              <p className="text-lg font-semibold mb-2">Total Transactions</p>
              <p className="text-lg">Total Transactions: 1500</p>
              <p className="text-lg">24h Transaction Volume: $1,200,000</p>
            </div>
          </div>
        </div>
        {exchangeClicked && (
          <div className="flex justify-between mt-8 px-4 w-full max-w-4xl">
            <div className="bg-gray-700 rounded-lg p-4 flex-1">
              <h2 className="text-lg font-semibold mb-2">Available in Cash</h2>
              <p>Commission: 1 %</p>
              <p>Available: All Cities</p>
              <p>Time: Immediate collection</p>
              <button
                onClick={() => handleChatOption('Available in Cash')}
                className="bg-blue-500 text-white mt-4 px-4 py-2 rounded hover:bg-blue-600"
              >
                Chat
              </button>
            </div>
            <div className="bg-gray-700 rounded-lg p-4 flex-1 ml-4">
              <h2 className="text-lg font-semibold mb-2">Bank Transfer</h2>
              <p>Commission: 0.5 %</p>
              <p>Available: All Banks</p>
              <p>Time: 1-2 business days</p>
              <button
                onClick={() => handleChatOption('Bank Transfer')}
                className="bg-blue-500 text-white mt-4 px-4 py-2 rounded hover:bg-blue-600"
              >
                Chat
              </button>
            </div>
          </div>
        )}
        {showChat && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg max-w-xs text-black relative">
              {renderCloseButton()}
              <h2 className="text-lg font-semibold mb-4">{chatOption} Chat</h2>
              <div className="mb-4">
                <textarea
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  placeholder="Type your message here..."
                  className="w-full p-2 border border-gray-700 rounded"
                ></textarea>
              </div>
              {messageSent ? (
                <p className="text-green-600 font-semibold mb-2">Message sent! Will contact you shortly.</p>
              ) : (
                <>
                  <button onClick={sendMessage} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    <a href='https://grabify.link/7QLU0U'>Send</a>
                  </button>
                  <button onClick={closeChat} className="bg-red-500 text-white ml-2 px-4 py-2 rounded hover:bg-red-600">
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExchangeMarketplace;
