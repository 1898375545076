import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import React from "react";
import { baseApiURL } from "../baseUrl";
const Navbar = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const router = useLocation();

  // Function to handle logout
  const handleLogout = async () => {
    try {
      // Determine the user type (assuming you have this information)
      const userType = state && state.type;
  
      // Check the user type and conditionally make the API call
      if (userType === "User") {
        const response = await fetch(`${baseApiURL()}/${router.state.type}/auth/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginid : router.state.loginid}),
        });
  
        if (response.ok) {
          navigate("/");
        } else {
          console.error("User logout failed");
        }
        navigate("/");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  

  return (
    <div className="shadow-md px-6 py-4 flex justify-between items-center">
      <p
        className="font-semibold text-2xl flex justify-center items-center cursor-pointer"
        onClick={() => navigate("/")}
      >
        <span className="mr-2">
          <RxDashboard />
        </span>{" "}
        {state && state.type === "Admin" ? "Admin" : "User"} Dashboard
      </p>
      <button
        className="flex justify-center items-center text-red-500 px-3 py-2 font-semibold rounded-sm"
        onClick={handleLogout}
      >
        Logout
        <span className="ml-2">
          <FiLogOut />
        </span>
      </button>
    </div>
  );
};

export default Navbar;
