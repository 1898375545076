import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase/config";
import { baseApiURL } from "../../../baseUrl";
import { FiUpload } from "react-icons/fi";
import emailjs from '@emailjs/browser';
const AddStudent = () => {
  const [file, setFile] = useState();
  const [data, setData] = useState({
    enrollmentNo: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profile: "",
    credential: "",
  });

  useEffect(() => {
    const uploadFileToStorage = async (file) => {
      toast.loading("Upload Photo To Storage");
      const storageRef = ref(
        storage,
        `Student Profile/${data.enrollmentNo}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error(error);
          toast.dismiss();
          toast.error("Something Went Wrong!");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            toast.dismiss();
            setFile();
            toast.success("Profile Uploaded To Storage");
            setData({ ...data, profile: downloadURL });
          });
        }
      );
    };
    file && uploadFileToStorage(file);
  }, [data, file]);


  // const addStudentProfile = (e) => {
  //   e.preventDefault();
  //   toast.loading("Adding Student");
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   axios
  //     .post(`${baseApiURL()}/user/details/addDetails`, data, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       toast.dismiss();
  //       if (response.data.success) {
  //         toast.success(response.data.message);
  //         sendEmail();
  //         axios
  //           .post(
  //             `${baseApiURL()}/user/auth/register`,
  //             { loginid: data.enrollmentNo, password: 112233 },
  //             {
  //               headers: headers,
  //             }
  //           )
  //           .then((response) => {
  //             toast.dismiss();
  //             if (response.data.success) {
  //               toast.success(response.data.message);
  //               setFile();
  //               setData({
  //                 enrollmentNo: "",
  //                 firstName: "",
  //                 middleName: "",
  //                 lastName: "",
  //                 email: "",
  //                 phoneNumber: "",
  //                 profile: "",
  //               });
  //             } else {
  //               toast.error(response.data.message);
  //             }
  //           })
  //           .catch((error) => {
  //             toast.dismiss();
  //             toast.error(error.response.data.message);
  //           });
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.dismiss();
  //       toast.error(error.response.data.message);
  //     });
  // };

  const addStudentProfile = (e) => {
    e.preventDefault();
    toast.loading("Adding Student");
    const headers = {
      "Content-Type": "application/json",
    };
  
    // Step 1: Create Student Credential
    axios
      .post(`${baseApiURL()}/user/auth/register`, { loginid: data.enrollmentNo, password: 112233 }, { headers: headers })
      .then((credentialResponse) => {
        if (credentialResponse.data.success) {
          toast.success(credentialResponse.data.message);
  
          // Get the ObjectId of the created credential
          const credentialObjectId = credentialResponse.data.data._id;
          console.log(credentialObjectId);
  
          // Step 2: Create Student Details with Credential ObjectId
          axios
            .post(`${baseApiURL()}/user/details/addDetails`, { ...data, credential: credentialObjectId }, { headers: headers })
            .then((detailsResponse) => {
              toast.dismiss();
              if (detailsResponse.data.success) {
                toast.success(detailsResponse.data.message);
                sendEmail();
                setFile();
                setData({
                  enrollmentNo: "",
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  profile: "",
                  credential: credentialObjectId,
                });
              } else {
                toast.error(detailsResponse.data.message);
              }
            })
            .catch((detailsError) => {
              toast.dismiss();
              toast.error(detailsError.response.data.message);
            });
        } else {
          toast.error(credentialResponse.data.message);
        }
      })
      .catch((credentialError) => {
        toast.dismiss();
        toast.error(credentialError.response.data.message);
      });
  };

  const form = useRef();

  const sendEmail = () => {
    const templateParams = {
      studentname: data.firstName,
      to: data.email, 
      enrollmentnumber : data.enrollmentNo,
    };
    emailjs.send('service_6iw3a5k', 'template_smqt6hq', templateParams, '-HQ_08N7f1ml_-oOK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form
      onSubmit={(e) => {
        addStudentProfile(e);
      }}
      ref={form}
      className="w-[70%] flex justify-center items-center flex-wrap gap-6 mx-auto mt-10"
    >
      <div className="w-[40%]">
        <label htmlFor="firstname" className="leading-7 text-sm ">
          Enter First Name<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="firstname"
          value={data.firstName}
          onChange={(e) => setData({ ...data, firstName: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="middlename" className="leading-7 text-sm ">
          Enter Middle Name
        </label>
        <input
          type="text"
          id="middlename"
          value={data.middleName}
          onChange={(e) => setData({ ...data, middleName: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="lastname" className="leading-7 text-sm ">
          Enter Last Name<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="lastname"
          value={data.lastName}
          onChange={(e) => setData({ ...data, lastName: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="enrollmentNo" className="leading-7 text-sm ">
          Enter Enrollment No<span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          id="enrollmentNo"
          value={data.enrollmentNo}
          onChange={(e) => setData({ ...data, enrollmentNo: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="email" className="leading-7 text-sm ">
          Enter Email Address<span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          id="email"
          value={data.email}
          onChange={(e) => setData({ ...data, email: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="phoneNumber" className="leading-7 text-sm ">
          Enter Phone Number<span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          id="phoneNumber"
          value={data.phoneNumber}
          onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
          className="w-full bg-blue-50 rounded border focus:border-dark-green focus:bg-secondary-light focus:ring-2 focus:ring-light-green text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="w-[40%]">
        <label htmlFor="file" className="leading-7 text-sm ">
          Select Profile
        </label>
        <label
          htmlFor="file"
          className="px-2 bg-blue-50 py-3 rounded-sm text-base w-full flex justify-center items-center cursor-pointer"
        >
          Upload
          <span className="ml-2">
            <FiUpload />
          </span>
        </label>
        <input
          hidden
          type="file"
          id="file"
          accept="image/*"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
      {data.profile && (
        <div className="w-full flex justify-center items-center">
          <img src={data.profile} alt="student" className="h-36" />
        </div>
      )}
      <button
        type="submit"
        className="bg-blue-500 px-6 py-3 rounded-sm mb-6 text-white"
      >
        Register new User
      </button>
    </form>
  );
};

export default AddStudent;